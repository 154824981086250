/* This example requires Tailwind CSS v2.0+ */
export default function Footer() {
  return (
    <footer className="mt-auto flex flex-col h-full">
      <div className="text-xs text-slate-400">
        <span className='m-1'>© 2021-2022 <a href="#" target="_blank" className='font-semibold'>Tianjin Yiling Technology Co., Ltd. 天津一零科技有限责任公司</a></span>
        {/* tech 备案号-2 cn */}
        <span className='m-1'>备案号：<a target="_blank" rel="nofollow" href="https://beian.miit.gov.cn/" className='font-semibold'>津ICP备2022000355号-{window.location.hostname.slice(-2) === 'cn' ? '1' : '2'}</a></span>
      </div>
    </footer>
  )
}
