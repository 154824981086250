import Nav from './Nav';
import Body from './Body';
import Footer from './Footer';

function App() {
  return (
    <div className="text-center">
      <Nav />
      <Body />
      <Footer />
    </div>
  )}

export default App;
